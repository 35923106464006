import { use } from "i18next";
import React, { useEffect } from "react";

import ScrollAnimation from 'react-animate-on-scroll';

const SmallHeightMarginWidth = ({ data }) => {
  console.log(data.itens[0].imagesrc.nodes[0].sourceUrl)

  var cols = data.cols

  useEffect(() => {
    if (typeof document !== 'undefined') {
      var video = document.getElementById("myVideoSmallHeightFullWidth");
      if (video)
        video.oncanplaythrough = function () {
          video.muted = true;
          video.play();
        }
    }
  }, [])

  return (
    <div className={data.classname ? data.classname : " black"}>
      <ScrollAnimation animateIn='fadeIn' delay={200}>
        <div className="container-margin container-small-height-full-width pb-0 pt-0">
          {cols !== null &&
            <p className=" pt-0 pb-0 fs-6 ILightBeta uppercase mb-3 container-small-height-full-width-title" >{data.cols[0].titleandtext.title}</p>
          }
          {data.itens[0].imagesrc.nodes[0].sourceUrl ?
            <img src={data.itens[0].imagesrc.nodes[0].sourceUrl} alt={data.itens[0].imagesrc.nodes[0].altText} />
            :
            <video muted={true} autoplay controls={false} loop playsinline id="myVideoSmallHeightFullWidth">
              <source src={data.itens[0].imagesrc.nodes[0].mediaItemUrl} alt={data.itens[0].imagesrc.nodes[0].altText} />
            </video>
          }
        </div>
      </ScrollAnimation >
    </div>
  );
}
export default SmallHeightMarginWidth;